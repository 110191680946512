
import Vue from "vue";
var owasp = require("owasp-password-strength-test");

owasp.config({
  allowPassphrases: true,
  maxLength: 128,
  minLength: 4,
  minPhraseLength: 20,
  minOptionalTestsToPass: 4
});

export default Vue.extend({
  name: "Password",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    fixedLabels: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true
    },
    isNew: {
      type: Boolean,
      required: true
    },
    passwordMessage: {
      type: String,
      required: false,
      default: ""
    },
    containerClass: {
      type: String,
      default: "flex"
    },
    inputsClass: {
      type: String,
      default: "w-full"
    },
    messageClass: {
      type: String,
      default: "w-full"
    }
  },
  data() {
    return {
      passwordFieldType: "password",
      checked: false,
      message: "",
      errors: [],
      password: "",
      confirmPassword: ""
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.password = "";
      this.confirmPassword = "";
    });
  },
  watch: {
    passwordConfirmed: {
      immediate: true,
      handler(isValid: boolean) {
        this.$emit("disable", !isValid);
        this.inputPassword(this.confirmPassword);
      }
    },
    password: {
      immediate: true,
      handler() {
        this.inputPassword("");
      }
    }
  },
  methods: {
    inputPassword(val: string) {
      this.$emit("input", {
        password: val,
        isValid: this.passwordConfirmed
      });
    },
    passwordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    save(): string {
      return this.passwordConfirmed ? this.password : "";
    },
    customFieldValidator() {
      return this.confirmPassword === "" || this.passwordConfirmed;
    },
    focusConfirmField(): void {
      const nextField: any = this.$refs[`field_password_confirm`];
      if (nextField && nextField.$el) {
        const nextFieldInput = nextField.$el.querySelector("input");
        if (nextFieldInput) {
          nextFieldInput.focus();
        }
      }
    },
    enterSubmit() {
      this.$emit("enterSubmit");

      const nextField: any = this.$refs[`field_password_confirm`];
      if (nextField && nextField.$el) {
        const nextFieldInput = nextField.$el.querySelector("input");
        if (nextFieldInput) {
          nextFieldInput.blur();
        }
      }
    }
  },
  computed: {
    isPasswordValid(): boolean {
      const isStrongPassword = owasp.test(this.password);
      return isStrongPassword.strong;
    },
    passwordErrors(): any[] {
      const isStrongPassword = owasp.test(this.password);
      return isStrongPassword.errors;
    },
    passwordConfirmed(): boolean {
      return this.isPasswordValid && this.confirmPassword === this.password;
    }
  }
});
