var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],class:_vm.containerClass},[_c('div',{class:_vm.inputsClass},[_c('TextField',{ref:"field_password",attrs:{"disabled":_vm.disabled,"fieldGroupClass":"pb-0 mt-2","label":_vm.__getText('fields', 'passwordFieldLabel'),"type":_vm.passwordFieldType,"placeholder":_vm.__getText('fields', 'passwordFieldLabel'),"value":_vm.password,"required":true,"fixedLabels":_vm.fixedLabels},on:{"input":function($event){_vm.password = $event},"onEnter":_vm.focusConfirmField}}),(_vm.isPasswordValid)?_c('TextField',{ref:"field_password_confirm",attrs:{"disabled":_vm.disabled,"fieldGroupClass":"pb-0 mt-6","label":_vm.__getText('fields', 'confirmPasswordFieldLabel'),"type":_vm.passwordFieldType,"placeholder":_vm.__getText('fields', 'confirmPasswordFieldLabel'),"required":true,"fixedLabels":_vm.fixedLabels,"customValidator":_vm.customFieldValidator,"validations":[
          {
            method: 'custom',
            error:
              _vm.confirmPassword === ''
                ? ' '
                : _vm.__getText('fields', 'confirmPasswordFieldValidateError')
          }
        ]},on:{"onEnter":_vm.enterSubmit,"input":function($event){return _vm.inputPassword(_vm.confirmPassword)}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}):_vm._e()],1)]),_c('div',{staticClass:"w-full flex mt-4 justify-end"},[_c('el-checkbox',{on:{"change":_vm.passwordVisibility},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v(_vm._s(_vm.__getText("fields", "showPasswordLabel")))])],1),(_vm.passwordErrors && _vm.passwordErrors.length)?_c('div',{staticClass:"bg-red-100 text-gray-800 p-6",attrs:{"title":"Password Requirements","show":true,"dismissible":false}},[_c('div',{staticClass:"mb-2 font-medium"},[_vm._v("Password Requirements")]),_c('ul',{staticClass:"list-disc pl-5 space-y-2 text-sm",attrs:{"role":"list"}},_vm._l((_vm.passwordErrors.slice(0, 3)),function(error){return _c('li',{key:error},[_vm._v(" "+_vm._s(error)+" ")])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }